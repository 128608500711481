<template>
  <div class="clearfix">
    <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            fade
            indicators
            background="#1A1A1A"
            :interval="2500"
          >
            <b-carousel-slide
              img-src="/img/sl/sl1.jpg"
            >
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em;">BIENVENIDO</span>
              <br>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em;">A</span>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em; color: #ffcc00"> NUESTRA</span>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em;"> COMPAÑIA</span>
              <br>
              <br>
              <br>
              <br>
              <br>
            </b-carousel-slide>

            <b-carousel-slide
              img-src="/img/sl/sl2.jpg"
            >
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em;">CONTAMOS CON UN</span>
              <br>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em;">GRAN PERSONAL</span>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em; color: #ffcc00"> DE TRABAJO</span>
              <br>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 2em;">Para dar un mejor servicio rápido y confiable</span>
              <br>
              <br>
              <br>
              <br>
              <br>
            </b-carousel-slide>

            <b-carousel-slide
              img-src="/img/sl/sl3.jpg"
            >
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em;">TRABAJAMOS CON</span>
              <br>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em;">VALORES Y</span>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em; color: #ffcc00"> HONESTIDAD</span>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
            </b-carousel-slide>

            <b-carousel-slide
              img-src="/img/sl/sl4.jpg"
            >
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em;">TRABAJOS CON PRINCIPIOS</span>
              <br>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em;">BASADOS EN</span>
              <span style="font-family: 'Montserrat', sans-serif; font-size: 4em; color: #ffcc00"> LA INDEPENDENCIA</span>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
            </b-carousel-slide>

          </b-carousel>
    <div class="container my-5" >
      <div class="row" style="text-align: justify;">
        <!-- /.col-lg-4 -->
        <div class="col-md-6">
          <img
            src="/img/varias/home.jpg"
            class="img-fluid"
          />
        </div>
        <div class="col-md-6">
          <p>
           MEDIDAS ESPECIALIZADAS Y CERTIFICACIONES LIMITADA – MEDICERT LTDA con NIT 901046311-2 organismo de inspección, se constituye legalmente en enero de 2017, con el objetivo de desarrollar su actividad comercial de realizar inspección de instalaciones eléctricas de conformidad con reglamentos técnicos vigentes y normas concordantes.
           </p>
           <p>
           MEDICERT LTDA se crea con el objetivo de satisfacer la demanda de servicios a clientes específicos que, por su condición de mercado, necesitan servicios de inspección de excelente calidad con eficiencia, donde los tiempos del proceso y la oportunidad de atención sean mínimos con el ánimo de optimizar la entrega en sus proyectos.
          </p>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  name: 'Home',
  data () {
    return {
    }
  },
  mounted () {
    this.$store.commit('setActualPage', 'Inicio')
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
/* Carousel base class */
/* .carousel {
    margin-bottom: 4rem;
} */

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    z-index: 150;
    bottom: 5rem;
}

/* Declare heights because of positioning of img element
.carousel-item {
    height: 32rem;
    background-color: #777;
}

.carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 32rem;
} */
</style>
